import React, { useState } from "react";
import { Carousel, PortalImage } from "@molecules";
import loadable from "@loadable/component";
import { Container, Text, Button, Icon } from "@atoms";
import { AppLink } from "@base";
import { m } from "framer-motion";
import t from "@utils/t";
import useLang from "@hooks/useLang";

const GrowingImage = loadable(() => import("@organisms/GrowingImage"));

const GrowingImageStories = ({ heading, stories, image }) => {
  const lang = useLang();
  const [hideImage, setHideImage] = useState(false);
  return (
    <GrowingImage
      heading={heading}
      headingAlign="center"
      image={image}
      smallerSpacer
      hideImage={hideImage}
    >
      <m.div
        onViewportEnter={() => setHideImage(true)}
        onViewportLeave={() => setHideImage(false)}
        viewport={{ margin: "-50%" }}
        className="overflow-hidden"
      >
        <Container frame>
          <Carousel hideArrows maxVisible={3}>
            {stories.map(s => (
              <AppLink className="group relative block w-full" to={s.uri}>
                <div className="relative w-full overflow-hidden rounded-t-full">
                  <PortalImage
                    image={s.image}
                    className="relative z-10 w-full opacity-0 transition duration-200 group-hover:opacity-100"
                    blackBackground
                  />
                  <div className="absolute inset-0 z-0 bg-black transition duration-200 group-hover:opacity-0">
                    <PortalImage
                      image={s.image}
                      className="w-full opacity-30"
                      blackBackground
                      grayscale
                    />
                  </div>
                  <div className="absolute inset-0 flex flex-col items-center justify-center gap-4 px-4 text-center text-white transition duration-200 group-hover:opacity-0">
                    <Text variant="h3">{s.title}</Text>
                    <Button>
                      {t("Expand Story", lang)}
                      <Icon name="arrow" className="h-4 w-4 rotate-90" />
                    </Button>
                  </div>
                </div>
                <Text
                  variant="caption"
                  className="mt-2 px-2 text-center text-white"
                >
                  {t("Photo courtesy of", lang)}
                </Text>
                <Text variant="caption" className="px-2 text-center text-white">
                  {s.image.attribution}
                </Text>
              </AppLink>
            ))}
          </Carousel>
        </Container>
      </m.div>
    </GrowingImage>
  );
};

export default GrowingImageStories;
